#root {
    height: 100%;
}
.backgroundImage {
    background-image: url(../../assets/img/fundo-compranachina.jpg);
    height: 100%; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (max-width: 720px) {
    .backgroundImage {
        background-image: none;
        height: auto;
        background-color: #ccc;
    }
}